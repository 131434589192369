define("discourse/plugins/stemaway-project-generation/discourse/routes/admin-plugins-ai-parameters", ["exports", "@ember/routing/route", "discourse/lib/ajax"], function (_exports, _route, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiParametersRoute extends _route.default {
    async model() {
      const response = await (0, _ajax.ajax)('/admin/site_settings.json');
      const siteSettings = response.site_settings || [];
      const getSettingValue = settingName => {
        const setting = siteSettings.find(s => s.setting === settingName);
        return setting ? setting.value : "";
      };
      const settings = {
        skill_extraction_prompt: getSettingValue("skill_extraction_prompt"),
        project_generation_prompt: getSettingValue("project_generation_prompt"),
        code_extraction_prompt: getSettingValue("code_extraction_prompt"),
        evaluation_prompt_text: getSettingValue("evaluation_prompt_text"),
        //   greeting_prompt: getSettingValue("greeting_prompt"),
        aivia_greeting_message: getSettingValue("aivia_greeting_message"),
        aivia_evaluation_prompt: getSettingValue("aivia_evaluation_prompt"),
        aivia_evaluation_questions_prompt: getSettingValue("aivia_evaluation_questions_prompt"),
        aivia_first_followup_question_prompt: getSettingValue("aivia_first_followup_question_prompt"),
        aivia_second_followup_question_prompt: getSettingValue("aivia_second_followup_question_prompt"),
        aivia_final_summary_prompt: getSettingValue("aivia_final_summary_prompt")
      };

      // console.log("Settings loaded into model:", settings);
      return settings;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.model = model;
    }
  }
  _exports.default = AiParametersRoute;
});
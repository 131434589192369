define("discourse/plugins/stemaway-project-generation/discourse/services/project-state", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax"], function (_exports, _service, _tracking, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ProjectStateService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "userSaved", [_tracking.tracked], function () {
      return false;
    }))();
    #userSaved = (() => (dt7948.i(this, "userSaved"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "buttonText", [_tracking.tracked], function () {
      return 'Submit';
    }))();
    #buttonText = (() => (dt7948.i(this, "buttonText"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "loadingMessage", [_tracking.tracked], function () {
      return '';
    }))();
    #loadingMessage = (() => (dt7948.i(this, "loadingMessage"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "extractedSkillsArray", [_tracking.tracked], function () {
      return [];
    }))();
    #extractedSkillsArray = (() => (dt7948.i(this, "extractedSkillsArray"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "responseReceived", [_tracking.tracked], function () {
      return false;
    }))();
    #responseReceived = (() => (dt7948.i(this, "responseReceived"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "showNoTopicsFoundPrompt", [_tracking.tracked], function () {
      return false;
    }))();
    #showNoTopicsFoundPrompt = (() => (dt7948.i(this, "showNoTopicsFoundPrompt"), void 0))();
    get requiredPoints() {
      return this.siteSettings.required_tacks;
    }
    static #_10 = (() => dt7948.n(this.prototype, "requiredPoints", [(0, _object.computed)('siteSettings.required_tacks')]))();
    get username() {
      return this.currentUser.username;
    }
    subtractPointsForProject(reason) {
      const username = this.username;
      const pointsToSubtract = this.requiredPoints;
      const data = {
        username,
        points: pointsToSubtract,
        reason
      };
      this.setLoadingState(true);
      (0, _ajax.ajax)("/stemaway-project-generation/subtract-user-points", {
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json"
      }).then(() => {
        this.userSaved = true;
        setTimeout(() => this.userSaved = false, 2000);
      }).catch(error => {
        console.error("Failed to subtract points: ", error);
        this.setLoadingState(false);
      });
    }
    setLoadingState(isLoading) {
      let message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this.isLoading = isLoading;
      this.buttonText = isLoading ? 'Processing...' : 'Submit';
      this.loadingMessage = message;
    }
  }
  _exports.default = ProjectStateService;
});
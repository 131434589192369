define("discourse/plugins/stemaway-project-generation/discourse/connectors/discovery-navigation-bar-above/skills-data", ["exports", "@ember/component", "discourse/lib/ajax", "@ember/object", "@ember/service", "discourse/models/category", "@ember/runloop"], function (_exports, _component, _ajax, _object, _service, _category, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    skillQuery: '',
    selectedSkill: '',
    availableSkills: [],
    topics: [],
    isLoading: false,
    skillHierarchyDisplayed: false,
    skillHierarchies: [],
    currentLoadingId: 0,
    isFadingOut: false,
    topicsJobData: [],
    siteSettings: (0, _service.inject)(),
    router: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    categoryMapping: {
      '445': 'Machine Learning',
      '446': 'Bioinformatics',
      '447': 'Full Stack',
      '448': 'UX/UI',
      '449': 'Job Data'
    },
    filteredSkills: (0, _object.computed)('skillQuery', function () {
      let query = this.get('skillQuery').toLowerCase();
      return Object.keys(this.availableSkills).filter(skill => skill.toLowerCase().includes(query));
    }),
    hasAccess: (0, _object.computed)('currentUser', 'siteSettings.project_generation_access_group', function () {
      const accessGroup = this.siteSettings.project_generation_access_group;
      if (!accessGroup) {
        return true;
      }
      return this.currentUser && this.currentUser.groups.some(group => group.name === accessGroup);
    }),
    isInCategory: (0, _object.computed)('router.currentURL', function () {
      let projectCategoryId = this.siteSettings.skope_ai_category;
      let categoryId = parseInt(projectCategoryId, 10);
      if (isNaN(categoryId)) {
        console.error('Invalid category ID:', projectCategoryId);
        return false;
      }
      let category = _category.default.findById(categoryId);
      if (category && this.router.currentURL.includes(`c/${category.slug}/${categoryId}`)) {
        return true;
      }
      return false;
    }),
    orderedCategories: (0, _object.computed)(function () {
      return ['Full Stack', 'Machine Learning', 'Bioinformatics', 'UX/UI'];
    }),
    init() {
      this.demandfirstrow = {};
      this.set('skillHierarchies', []);
      this._super(...arguments);
      if (!this.isInCategory) {
        return;
      }
      this.set('isSkillsLoading', true);
      (0, _ajax.ajax)('/plugins/stemaway-project-generation/json/matched_skills.json').then(allSkills => {
        let skillsAndTags = Object.entries(allSkills);
        (0, _ajax.ajax)(`/stemaway-project-generation/fetch_skills_with_tags?skills=${skillsAndTags.map(_ref => {
          let [_, tag] = _ref;
          return encodeURIComponent(tag);
        }).join(',')}`).then(response => {
          let filteredSkillsObject = {};
          skillsAndTags.forEach(_ref2 => {
            let [skillName, tag] = _ref2;
            if (response.topics_by_skill[tag] && response.topics_by_skill[tag].length > 0) {
              filteredSkillsObject[skillName] = tag;
            }
          });
          this.set('availableSkills', filteredSkillsObject);
          this.set('isSkillsLoading', false);
        }).catch(error => {
          console.error('Error checking skills with topics:', error);
          this.set('isSkillsLoading', false);
        });
      }).catch(error => {
        console.error('Error fetching skills:', error);
        this.set('isSkillsLoading', false);
      });
      (0, _ajax.ajax)(`/plugins/stemaway-project-generation/json/similar_skills.json`).then(similarSkillsData => {
        this.set('similarSkillsData', similarSkillsData);
      }).catch(error => {
        console.error('Error fetching similar skills data:', error);
      });
    },
    actions: {
      selectSkill(skill) {
        this.setProperties({
          currentLoadingId: this.incrementProperty('currentLoadingId'),
          skillHierarchies: [],
          isLoading: true
        });
        this._loadSkillData(skill, this.get('currentLoadingId'));
      },
      selectSimilarSkill(similarSkill) {
        this.set('isFadingOut', true);
        (0, _runloop.later)(() => {
          this.set('isFadingOut', false);
          this.setProperties({
            currentLoadingId: this.incrementProperty('currentLoadingId'),
            skillHierarchies: [],
            isLoading: true
          });
          this._loadSkillData(similarSkill, this.get('currentLoadingId'));
        }, 0);
      },
      updateSkillQuery(newValue) {
        this.set('skillQuery', newValue);
      }
    },
    _loadSkillData(skill, loadingId) {
      const convertedSkill = this.availableSkills[skill] || skill;
      this.demandfirstrow = {};
      this.set('similarTopics', []);
      this.checkTopics(convertedSkill);
      this.fetchTopics(skill, loadingId);
      if (this.similarSkillsData && this.similarSkillsData[convertedSkill]) {
        let updatedSimilarSkills = this.similarSkillsData[convertedSkill].map(convertedTag => {
          return Object.keys(this.availableSkills).find(key => this.availableSkills[key] === convertedTag) || convertedTag;
        }).filter(skill => this.availableSkills[skill]);
        this.set('similarSkills', updatedSimilarSkills);
      } else {
        this.set('similarSkills', []);
      }
      this.setProperties({
        selectedSkill: skill,
        skillQuery: '',
        groupedTopics: {},
        topicsJobData: [],
        skillHierarchyDisplayed: false
      });
      if (!this.hasAccess) {
        return;
      }
    },
    checkTopics(skills) {
      const skillTags = skills.split(',').map(skill => skill.trim().toLowerCase());
      (0, _ajax.ajax)(`/c/${this.siteSettings.project_category}.json`).then(response => {
        const topicPromises = response.topic_list.topics.filter(topic => skillTags.some(tag => topic.tags.includes(tag))).map(topic => {
          return (0, _ajax.ajax)(`/t/${topic.id}.json`).then(topicResponse => {
            const firstPost = topicResponse.post_stream.posts[0].cooked;
            const objectiveLine = this.extractObjectiveLine(firstPost);
            return {
              ...topic,
              objectiveLine
            };
          });
        });
        Promise.all(topicPromises).then(updatedTopics => {
          this.set('similarTopics', updatedTopics);
        });
      }).catch(error => console.error('Error fetching topics:', error));
    },
    extractObjectiveLine(content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const text = doc.body.textContent || "";
      const objectiveMatch = text.match(/Objective:?\s*(.*?)\s*(\n|\.)/i);
      if (objectiveMatch && objectiveMatch.length > 1) {
        return objectiveMatch[1].trim() + '...';
      }
      return '';
    },
    // formatSkillForTag(skill) {
    //   return skill.toLowerCase().replace(/\s+/g, '-').replace(/\./g, '');
    // },

    fetchTopics(skill, loadingId) {
      const encodedSkill = encodeURIComponent(skill);
      (0, _ajax.ajax)(`/stemaway-project-generation/fetch-filtered-topics?skill=${encodedSkill}`).then(response => {
        if (loadingId !== this.get('currentLoadingId')) {
          return;
        }
        let topicsByCategoryName = {};
        response.topics.forEach(topic => {
          let categoryName = this.categoryMapping[topic.category_id.toString()];
          if (!topicsByCategoryName[categoryName]) {
            topicsByCategoryName[categoryName] = [];
          }
          if (!topicsByCategoryName[categoryName].find(t => t.title === topic.title)) {
            topicsByCategoryName[categoryName].push(topic);
          }
        });
        this.set('groupedTopics', topicsByCategoryName);
        this.set('topicsJobData', response.job_data || []);
        let hasTopics = Object.values(topicsByCategoryName).some(topics => topics.length) || this.topicsJobData.length > 0;
        this.set('hasTopics', hasTopics);
        return this.fetchTopicDetails();
      }).catch(error => {
        console.error('Error fetching filtered topics:', error);
      }).finally(() => {
        if (loadingId === this.get('currentLoadingId')) {}
      });
    },
    fetchTopicDetails() {
      let groupedTopics = this.get('groupedTopics');
      let jobDataTopics = this.get('topicsJobData');
      Object.keys(this.categoryMapping).forEach(catId => {
        this.demandfirstrow[this.categoryMapping[catId]] = false;
      });
      Object.keys(groupedTopics).forEach(categoryName => {
        groupedTopics[categoryName].forEach((topic, index) => {
          this.processTopic(topic, categoryName, false, index === 0);
        });
      });
      jobDataTopics.forEach(topic => {
        this.processTopic(topic, '', true);
      });
    },
    processTopic(topic, categoryName) {
      let isJobDataTopic = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let canDisplayFirstRow = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      (0, _ajax.ajax)(`/t/${topic.id}.json`).then(response => {
        let content = response.post_stream.posts[0].cooked;
        let parser = new DOMParser();
        let doc = parser.parseFromString(content, "text/html");
        if (!isJobDataTopic) {
          this.processDemandAnalysis(doc, topic, categoryName, canDisplayFirstRow);
        } else {
          this.processCompanyMetrics(doc, topic);
        }
        let skillHierarchy = this.extractSkillHierarchy(doc);
        if (skillHierarchy.length > 0) {
          let isUnique = !this.skillHierarchies.some(existingHierarchy => JSON.stringify(existingHierarchy) === JSON.stringify(skillHierarchy));
          if (isUnique) {
            this.skillHierarchies.pushObject(skillHierarchy);
          }
        }
      }).catch(error => {
        console.error('Error fetching topic content:', error);
      });
    },
    extractSkillHierarchy(doc) {
      let paragraphs = doc.querySelectorAll('ul > li > p');
      let skillHierarchyText = '';
      paragraphs.forEach(p => {
        if (p.textContent.includes('Skill Hierarchy:')) {
          skillHierarchyText = p.textContent.replace('Skill Hierarchy:', '').trim();
        }
      });
      if (typeof skillHierarchyText === 'string' && skillHierarchyText.length > 0) {
        let splits = skillHierarchyText.split('→').map(skill => skill.trim());
        this.set('isLoading', false);
        return splits;
      } else {
        return [];
      }
    },
    processDemandAnalysis(doc, topic, categoryName, canDisplayFirstRow) {
      let h3Elements = doc.querySelectorAll('h3');
      let demandAnalysisData = [];
      let firstRowData = null;
      h3Elements.forEach(h3 => {
        if (h3.textContent.includes("Demand Analysis")) {
          let demandAnalysisTable = h3.nextElementSibling.querySelector('table');
          if (demandAnalysisTable) {
            let rows = demandAnalysisTable.querySelectorAll('tr');
            for (let i = 1; i < rows.length && i < 4; i++) {
              let attribute = rows[i].cells[0].textContent.trim();
              if (attribute.includes('%')) continue;
              let detail = rows[i].cells[1].querySelectorAll('img[title=":star:"]').length;
              let rowData = {
                attribute,
                detail
              };
              if (i === 1 && canDisplayFirstRow && !this.demandfirstrow[categoryName]) {
                let attributeWithCategory = attribute.replace("the Field", categoryName);
                firstRowData = {
                  attribute: attributeWithCategory,
                  detail
                };
                this.demandfirstrow[categoryName] = true;
              } else if (i > 1) {
                demandAnalysisData.push(rowData);
              }
            }
          }
        }
      });
      (0, _object.set)(topic, 'firstRowDemandAnalysis', firstRowData);
      (0, _object.set)(topic, 'demandAnalysisSection', demandAnalysisData);
    },
    processCompanyMetrics(doc, topic) {
      let h3Elements = doc.querySelectorAll('h3');
      let companyMetricsHeader = Array.from(h3Elements).find(h3 => h3.textContent.includes("Company Metrics"));
      if (companyMetricsHeader) {
        let companyMetricsTable = companyMetricsHeader.nextElementSibling.querySelector('table');
        if (companyMetricsTable) {
          let rows = companyMetricsTable.querySelectorAll('tr');
          rows.forEach(row => {
            let header = row.cells[0].textContent.trim();
            if (header === 'Average Salary') {
              (0, _object.set)(topic, 'averageSalary', row.cells[1].textContent.trim());
            }
          });
        }
      }
    }
  });
});
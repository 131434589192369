define("discourse/plugins/stemaway-project-generation/discourse/connectors/extra-nav-item/ai-chat-button", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    appEvents: (0, _service.inject)(),
    activeButton: null,
    actions: {
      toggleStudentLinks() {
        if (this.activeButton === 'students') {
          this.set('activeButton', null);
          this.appEvents.trigger('ai-chat:hide-message');
        } else {
          this.set('activeButton', 'students');
          this.appEvents.trigger('ai-chat:show-student-links');
        }
      },
      toggleIndustryLinks() {
        if (this.activeButton === 'industry') {
          this.set('activeButton', null);
          this.appEvents.trigger('ai-chat:hide-message');
        } else {
          this.set('activeButton', 'industry');
          this.appEvents.trigger('ai-chat:show-industry-links');
        }
      }
    }
  });
});
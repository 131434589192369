define("discourse/plugins/stemaway-project-generation/discourse/helpers/sub", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SubHelper extends _helper.default {
    compute(_ref) {
      let [a, b] = _ref;
      return a - b;
    }
  }
  _exports.default = SubHelper;
});
define("discourse/plugins/stemaway-project-generation/discourse/helpers/split", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SplitHelper extends _helper.default {
    compute(_ref) {
      let [string, separator] = _ref;
      return string ? string.split(separator) : [];
    }
  }
  _exports.default = SplitHelper;
});